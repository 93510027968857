import React, { PropsWithChildren, useEffect, useState } from 'react'

import { I18nextProvider } from 'react-i18next'
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom'

import { Language } from '@dentalux/common'
import { LoginPage, ProtectedRoute, SecurityProvider } from '@dentalux/security'

import { CssBaseline, makeStyles, ThemeProvider } from '@material-ui/core'

import * as Sentry from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'

import AppRouter from './AppRouter'
import AppShell from './components/AppShell'
import FullScreenSpinner from './components/FullScreenSpinner'
import { UndoNotiVariant } from './components/UndoNotiVariant/UndoNotiVariant'
import queryClient from './config/queryClient'
import getEnvironmentName from './helpers/getEnvironmentName'
import AnalyticsProvider from './providers/AnalyticsProvider'
import AnalyticsToggleProvider from './providers/AnalyticsToggleProvider'
import AnamnesisDetailsProvider from './providers/AnamnesisProvider'
import AppointmentsProvider from './providers/AppointmentsProvider'
import { AppointmentUndoDialogProvider } from './providers/AppointmentUndoDialogProvider'
import AuthProvider from './providers/AuthProvider'
import BitComponentsProvider from './providers/BitComponentsProvider'
import CalendarViewSettingsProvider from './providers/CalendarSettingsProvider'
import CancelledAppointmentsToggleProvider from './providers/CancelledAppointmentsToggleProvider'
import ColorsProvider from './providers/ColorsProvider'
import { CreateConversationProvider } from './providers/CreateConversationProvider'
import CurrentClinicProvider from './providers/CurrentClinicProvider'
import DrawerCalendarStateProvider from './providers/DrawerCalendarStateProvider'
import { EmployeesFiltersProvider } from './providers/EmployeesFiltersProvider'
import FeatureFlagsProvider from './providers/FeatureFlags'
import LanguageProvider from './providers/LanguageProvider'
import { ObfuscationProvider } from './providers/ObfuscationProvider'
import { OpenAppointmentsEmployeesFiltersProvider } from './providers/OpenAppointmentsEmployeesFiltersProvider'
import { PatientDetailsModalProvider } from './providers/PatientDetailsModalProvider'
import PosthogProvider from './providers/PosthogProvider'
import PractitionersProvider from './providers/PractitionersProvider'
import RoomProvider from './providers/RoomsProvider'
import SearchTermProvider from './providers/SearchTermProvider'
import ServerSentEventsProvider from './providers/ServerSentEventsProvider'
import { SideNavigationProvider } from './providers/SideNavigationProvider'
import { SnackbarProvider } from './providers/SnackbarProvider'
import TodoListTasksProvider from './providers/TodoListTasksProvider'
import UpcomingAppointmentsProvider from './providers/UpcomingAppointmentsProvider'
import { UpcomingEmployeesFiltersProvider } from './providers/UpcomingEmployeesFiltersProvider'
import * as i18n from './services/i18n'
import theme from './theme'

const applyProviders = (
  baseComponent: React.ReactNode,
  // eslint-disable-next-line @typescript-eslint/ban-types
  providers: React.FC<{ children: React.ReactNode }>[]
): React.ReactNode => {
  const reversedProviders = [...providers].reverse()

  return reversedProviders.reduce<React.ReactNode>((acc, CurrentProvider) => {
    return <CurrentProvider>{acc}</CurrentProvider>
  }, baseComponent)
}

const BaseProviders = ({ children }: PropsWithChildren<unknown>) => {
  return <>{applyProviders(children, [AuthProvider, LanguageProvider])}</>
}

const withProviders = (component: React.ReactNode) => {
  return applyProviders(component, [
    BitComponentsProvider,
    AppointmentUndoDialogProvider,
    CalendarViewSettingsProvider,
    DrawerCalendarStateProvider,
    CurrentClinicProvider,
    FeatureFlagsProvider,
    ServerSentEventsProvider,
    PractitionersProvider,
    EmployeesFiltersProvider,
    UpcomingEmployeesFiltersProvider,
    OpenAppointmentsEmployeesFiltersProvider,
    AnalyticsToggleProvider,
    AnalyticsProvider,
    ColorsProvider,
    SearchTermProvider,
    CreateConversationProvider,
    PatientDetailsModalProvider,
    SideNavigationProvider,
    CancelledAppointmentsToggleProvider,
    UpcomingAppointmentsProvider,
    AppointmentsProvider,
    TodoListTasksProvider,
    AnamnesisDetailsProvider,
    RoomProvider,
    ObfuscationProvider,
  ])
}

function App() {
  //  Do not log local errors
  const environment = getEnvironmentName()
  const dsn = process.env.REACT_APP_SENTRY_DSN_KEY

  if (dsn && environment !== 'development') {
    Sentry.init({
      dsn,
      environment,
      integrations: (integrations) => {
        // remove OnUnhandledRejection event listener because we have a custom interceptor
        return integrations.filter((integration) => integration.name === 'OnUnhandledRejection')
      },
    })
  }

  const useStyles = makeStyles(() => ({
    containerAnchorOriginTopRight: {
      height: 5000,
      overflow: 'auto',
      display: 'unset',
      paddingBottom: 32,
      zIndex: 1300,
      // We need to modify .MuiCollapse-container class (direct containerAnchor's child),
      // but notistack doesn't expose API for that
      '& > div': {
        marginTop: 40,
      },
    },
    anchorOriginTopRight: {
      transform: 'unset',
    },
  }))

  const classes = useStyles()

  const [loadingTranslations, setLoadingTranslations] = useState(true)

  useEffect(() => {
    i18n.init().finally(() => {
      setLoadingTranslations(false)
    })
  }, [setLoadingTranslations])

  if (loadingTranslations) return <div />

  return (
    <Router>
      <SecurityProvider
        config={{
          loading: <FullScreenSpinner />,
          env: window.env.REACT_APP_HADES_ENV as 'staging' | 'production',
          redirectUri: `${window.location.origin}${window.location.pathname}`,
          defaultLanguage: i18n.default.language as Language,
          enableImpersonation: true,
        }}
      >
        <PosthogProvider>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <I18nextProvider i18n={i18n.default}>
                <BaseProviders>
                  <CssBaseline />

                  <Switch>
                    <Route exact path="/login">
                      <LoginPage title="Calmaster" />
                    </Route>
                    <ProtectedRoute path="/calendar/:view?/:date?/:sideNav?/:clinicReferenceId?/:appointmentReferenceId?/:source?">
                      {withProviders(
                        <SnackbarProvider
                          Components={{
                            undoNotiVariant: UndoNotiVariant,
                          }}
                          maxSnack={20}
                          classes={{
                            containerAnchorOriginTopRight: classes.containerAnchorOriginTopRight,
                            anchorOriginTopRight: classes.anchorOriginTopRight,
                          }}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          autoHideDuration={3000}
                        >
                          <AppShell>
                            <AppRouter />
                          </AppShell>
                        </SnackbarProvider>
                      )}
                    </ProtectedRoute>
                    <ProtectedRoute exact path="/">
                      {withProviders(
                        <SnackbarProvider
                          maxSnack={20}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          autoHideDuration={3000}
                        >
                          <AppShell>
                            <AppRouter />
                          </AppShell>
                        </SnackbarProvider>
                      )}
                    </ProtectedRoute>
                    <Redirect to="/" />
                  </Switch>
                </BaseProviders>
              </I18nextProvider>
            </ThemeProvider>
          </QueryClientProvider>
        </PosthogProvider>
      </SecurityProvider>
    </Router>
  )
}

export default App
