import { createContext, useContext, useReducer, useEffect, ReactNode } from 'react'

import { PatientDetails } from '../@types/PatientDetailsResult'

enum actionTypes {
  setActivePatient = 'setActivePatient',
  clearActivePatient = 'clearActivePatient',
  clearPatients = 'clearPatients',
  removeMinimizedPatient = 'removeMinimizedPatient',
  minimizePatient = 'minimizePatient',
  maximizePatient = 'maximizePatient',
}

type Action = { type: actionTypes; payload: any }

type State = {
  activePatientReferenceId: string | null
  minimizedPatients: PatientDetails[]
}

type Dispatch = (action: Action) => void

type ProviderProps = {
  children: ReactNode
}

const MINIMIZED_PATIENTS_STORAGE_KEY = 'minimizedpatients'

const PatientDetailsModalContext = createContext<State | undefined>(undefined)

const PatientDetailsModalDispatch = createContext<Dispatch | undefined>(undefined)

const persistMinimizedPatients = (data: PatientDetails[]) => {
  try {
    localStorage.setItem(MINIMIZED_PATIENTS_STORAGE_KEY, JSON.stringify(data))
  } catch (e) {
    console.error('Unable to write in localStorage')
  }
}

const readMinimizedPatientsFromStorage = () => {
  try {
    const data = localStorage.getItem(MINIMIZED_PATIENTS_STORAGE_KEY)

    if (!data) return []

    return JSON.parse(data)
  } catch (e) {
    console.error('Unable to read from localStorage')
    return []
  }
}

const defaultState: State = {
  activePatientReferenceId: null,
  minimizedPatients: readMinimizedPatientsFromStorage(),
}

const patientDetailsModalReducer = (state: State, action: Action) => {
  switch (action.type) {
    case actionTypes.setActivePatient:
    case actionTypes.clearActivePatient: // the payload for clearActivePatient is null so it will close the modal
      return {
        ...state,
        activePatientReferenceId: action.payload,
      }
    case actionTypes.removeMinimizedPatient:
      return {
        ...state,
        minimizedPatients: state.minimizedPatients.filter(
          (patient: PatientDetails) => patient.referenceId !== action.payload
        ),
      }
    case actionTypes.minimizePatient:
      return {
        ...state,
        activePatientReferenceId: null,
        minimizedPatients: [
          ...state.minimizedPatients.filter(
            (patient: PatientDetails) => patient.referenceId !== action.payload.referenceId
          ),
          action.payload,
        ],
      }
    case actionTypes.maximizePatient:
      return {
        ...state,
        activePatientReferenceId: action.payload,
        minimizedPatients: state.minimizedPatients.filter(
          (patient: PatientDetails) => patient.referenceId !== action.payload
        ),
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

export const PatientDetailsModalProvider = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(patientDetailsModalReducer, defaultState)

  useEffect(() => {
    persistMinimizedPatients(state.minimizedPatients)
  }, [state.minimizedPatients])

  return (
    <PatientDetailsModalContext.Provider value={state}>
      <PatientDetailsModalDispatch.Provider value={dispatch}>{children}</PatientDetailsModalDispatch.Provider>
    </PatientDetailsModalContext.Provider>
  )
}

export const usePatientDetailsModalState = (): State => {
  const context = useContext(PatientDetailsModalContext)

  if (context === undefined) {
    throw new Error('usePatientDetailsModalState must be used within PatientDetailsModalProvider')
  }

  return context
}

export const usePatientDetailsModalDispatch = (): Dispatch => {
  const context = useContext(PatientDetailsModalDispatch)

  if (context === undefined) {
    throw new Error('usePatientDetailsModalDispatch must be used within PatientDetailsModalProvider')
  }

  return context
}

export const setActivePatient = (patientReferenceId: string): Action => ({
  type: actionTypes.setActivePatient,
  payload: patientReferenceId,
})

export const clearActivePatient = (): Action => ({
  type: actionTypes.clearActivePatient,
  payload: null,
})

export const removeMinimizedPatient = (patientReferenceId: string): Action => ({
  type: actionTypes.removeMinimizedPatient,
  payload: patientReferenceId,
})

export const minimizePatient = (patient: PatientDetails): Action => ({
  type: actionTypes.minimizePatient,
  payload: patient,
})

export const maximizePatient = (patientReferenceId: string): Action => ({
  type: actionTypes.maximizePatient,
  payload: patientReferenceId,
})
