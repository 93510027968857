import { TFunction } from 'react-i18next'

import { CallManagementLabels, Labels, PatientTodayLabels } from '@dentalux/ui-library-core'

import { CallTaskStates } from '../../@types/CallTask'
import { Conversation, MessageDirection } from '../../@types/Conversation'
import { getAppointmentDetailsLabels } from '../../helpers/labels'

// We want the conversations that have not been read yet
const isUnread = ({ hasBeenRead }: Conversation) => !hasBeenRead

export const getUnreadConversations = (conversations: Conversation[]): number => conversations.filter(isUnread).length

export const getUnansweredConversationsCount = (conversations: Conversation[]): number => {
  const result = conversations.filter((conversation: Conversation) => {
    return conversation.latestMessages[0]?.direction === MessageDirection.INBOUND
  })

  return result.length
}

export const getCallManagementLabels = (t: TFunction): CallManagementLabels => ({
  card: {
    callbackTime: {
      calledPast: t('called_at_time_past'),
      calledToday: t('called_at_time_today'),
      ignoredPast: t('ignored_at_time_past'),
      ignoredToday: t('ignored_at_time_today'),
      notReachedPast: t('not_reached_at_past'),
      notReachedToday: t('not_reached_at_today'),
      days: t('time_spent_day_plural'),
      hours: t('time_spent_hours_plural'),
      minutes: t('time_spent_minutes'),
      seconds: 'MISSING TRANSLATION SECONDS',
    },
    name: {
      anonymousCaller: t('anonymous_caller'),
    },
    newAppointment: t('add_new_appointment_to_patient_title'),
    status: {
      ignoreCall: t('ignore_call'),
      ignoreForm: {
        cancel: t('cancel_call_action'),
        ignoreCall: t('ignore_call'),
        placeholder: t('comment_call_action'),
        submit: t('confirm_call_action_ignore'),
      },
      statusCalling: t('calling'),
      statusLabel: Object.entries(CallTaskStates).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: t(`callState_${value}`),
        }),
        {}
      ),
    },
    today: t('today'),
    yesterday: t('yesterday'),
  },
  openCalls: t('open_calls'),
  resolvedCalls: t('resolved_calls'),
  callNotification: {
    incoming_call: t('incoming_call'),
    ongoing_call: t('ongoing_call'),
    anonymous_caller: t('anonymous_caller'),
    add_new_appointment_to_patient_title: `+ ${t('add_new_appointment_to_patient_title')}`,
    patients_information: t('patients_information'),
    chemistry: {
      PLUS: t('amazing_chemistry'),
      NORMAL: t('normal_chemistry'),
      MINUS: t('bad_chemistry'),
      STOP: t('blacklist_chemistry'),
    },
  },
})

export const getTodoTabLabels = (t: TFunction): Labels => ({
  openTodos: t('todo_list.open'),
  doneTodos: t('todo_list.done'),
  patientDetails: t('patient_details'),
  ignoreTodo: t('ignore_todo'),
  recallTodo: t('recall_todo'),
  call: t('call'),
  description: {
    costPlan: t('todo_list.modal.description.cost_plan'),
    default: t('todo_list.modal.description.default'),
    followUpAppointment: t('todo_list.modal.description.followup_appointment'),
  },
  origin: {
    autoAcceptedHkpNoFollowup: t('todo_list.origin.autoAcceptedHkpNoFollowup'),
    autoHkpFollowup: t('todo_list.origin.autoHkpFollowup'),
    autoOnlineCancellation: t('todo_list.origin.autoOnlineCancellation'),
    manual: t('todo_list.origin.manual'),
  },
  category: {
    followupAppointment: t('todo_list.followup_appointment'),
    appointmentRescheduling: t('todo_list.modal.category.reschedule_appointment'),
    openHKP: t('todo_list.modal.category.open_hkp'),
    costPlan: t('todo_list.modal.category.cost_plan'),
  },
  modal: {
    callPatient: t('call_patient'),
    title: t('todo_list.modal.title'),
    openInCalendar: t('open_in_calendar'),
    followupDescription: t('todo_list.modal.followup_description'),
    scheduleAppointment: t('todo_list.modal.schedule_appointment'),
  },
  status: {
    todo: t('to_do'),
    done: t('task_status_done'),
    recall: t('recall'),
  },
  comment: {
    placeholder: t('comment_call_action'),
    cancel: t('cancel_button'),
    ignore: t('confirm_call_action_ignore'),
    done: t('confirm_call_action_done'),
    recall: t('confirm_call_action_recall'),
  },
  sort: {
    relevance: t('todo_list.relevance'),
    newest: t('todo_list.newest'),
    oldest: t('todo_list.oldest'),
  },
  filter: {
    taskTypes: t('todo_list.task_types'),
    appointmentTypes: t('todo_list.appointment_types'),
  },
})

export const getOpenAppointmentsLabels = (t: TFunction) => ({
  schedule: t('schedule'),
  appointmentDetails: getAppointmentDetailsLabels(t),
})

export const getPatientTodayLabels = (t: TFunction): PatientTodayLabels => ({
  appointment: {
    tooltip: t('appointment.tooltip'),
    status: {
      open: t('history_open'),
      inClinic: t('history_in_clinic'),
      inWaitingRoom: t('history_in_waiting_room'),
      inTreatmentRoom: t('history_in_treatment_room'),
      inTreatment: t('history_in_treatment'),
      treated: t('history_treatment_ended'),
      controlled: t('history_controlled'),
      cancelled: t('history_canceled'),
      cancelReason: {
        NO_SHOW: t('appointment_cancel.NO_SHOW'),
        CANCELED: t('appointment_cancel.CANCELED'),
        CANCELED_BY_PRACTICE: t('appointment_cancel.CANCELED_BY_PRACTICE'),
      },
      controlStep: {
        one: t('control_step.one'),
        two: t('control_step.two'),
      },
    },
  },
  room: t('room'),
  insuranceProofState: {
    valid: t('insurance_proof_state_valid'),
    invalid: t('insurance_proof_state_invalid'),
  },
  filter: {
    today: t('today'),
    next: t('jump_to_upcoming'),
    dayPicker: {
      cancelButtonLabel: t('task_cancel'),
      okButtonLabel: t('task_save'),
    },
    practitioner: {
      statuses: {
        titles: {
          practitioners: t('employees'),
          statuses: t('status'),
        },
        types: {
          CANCELED: t('history_canceled'),
          CONTROLLED_ACCOUNTING: t('control_step.two'),
          CONTROLLED_PRACTITIONER: t('control_step.one'),
          IN_TREATMENT: t('history_in_treatment'),
          IN_TREATMENT_ROOM: t('history_in_treatment_room'),
          OPEN_IN_CLINIC: t('history_open_in_clinic'),
          OPEN_NOT_IN_CLINIC: t('history_open_not_in_clinic'),
          TREATED: t('history_treatment_ended'),
          WAITING: t('history_in_waiting_room'),
        },
      },
      search: t('search'),
      title: t('practitioners'),
      noResults: t('none'),
      toggles: {
        ALL: t('all_employees'),
        ASSISTANT: t('all_assistants'),
        ACCOUNTANT: t('all_accountants'),
        HYGIENIST: t('all_hygienists'),
        DENTIST: t('all_dentists'),
        CLINIC_MANAGER: t('all_clinic_managers'),
        RECEPTIONIST: t('all_receptionists'),
        DENTAL_TECHNICIAN: t('all_dental_technicians'),
        DOCTOR: t('all_doctors'),
        DEACTIVATED: t('all_deactivated_employees'),
      },
    },
  },
  patientCreditWorthinessStatus: t('patient_creditworthiness_status'),
  careLevel: t('care_level'),
  anamnesis: {
    tooltip: t('anamnesis.tooltip'),
    formStatus: t('anamnesis.form_status'),
    steps: {
      anamnesis: t('anamnesis.steps.anamnesis'),
      factoringAgreement: t('anamnesis.steps.factoring_agreement'),
    },
    recollectCancel: t('anamnesis_recollect_cancel'),
    recollectConfirm: t('anamnesis_recollect_confirm'),
    retriggerDocument: t('retrigger_document'),
    generateMissingDocuments: t('anamnesis.generate_missing_documents'),
    sendLink: t('anamnesis.action.send_link'),
    smsSent: t('anamnesis.sms_sent'),
    sendSmsAgain: t('anamnesis.action.send_again'),
    generateTabletCode: t('anamnesis.action.generate_tablet_code'),
  },
  insurance: {
    SOCIAL_INSURANCE: t('insurance_SOCIAL_INSURANCE'),
    PRIVATE_INSURANCE: t('insurance_PRIVATE_INSURANCE'),
    ADDITIONAL_PRIVATE_INSURANCE: t('insurance_ADDITIONAL_PRIVATE_INSURANCE'),
  },
  chemistry: {
    PLUS: t('amazing_chemistry'),
    NORMAL: t('normal_chemistry'),
    MINUS: t('bad_chemistry'),
    STOP: t('blacklist_chemistry'),
  },
})
