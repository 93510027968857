import React, { Dispatch, PropsWithChildren, SetStateAction, useEffect, useMemo, useState } from 'react'

import { useServerSentEvent, ServerSentResourceType } from '@dentalux/common'

import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { Status, TasksSize } from '../@types/TodoListTask'
import withQueryParams from '../helpers/withQueryParams'
import useQueriesAuth from '../hooks/useQueriesAuth'
import { listTasksSize } from '../services/api/todoList'

import { useCurrentClinicProvider } from './CurrentClinicProvider'

type ContextType = {
  todos: number
  queryDetails?: Omit<UseQueryResult<TasksSize>, 'data'>
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

export const Context = React.createContext<ContextType>({
  todos: 0,
  isOpen: false,
  setIsOpen: () => {},
})

const TodoListTasksProvider = ({ children }: PropsWithChildren) => {
  const { selectedClinic } = useCurrentClinicProvider()
  const [isOpen, setIsOpen] = useState(false)
  const { skipTodoListQuery: skip } = useQueriesAuth()

  const queryKey = useMemo(
    () => [
      'todo-list-tasks-size',
      {
        clinicReferenceId: selectedClinic?.referenceId,
        statuses: [Status.TO_DO, Status.IN_PROGRESS],
      },
    ],
    [selectedClinic]
  )

  const todos = useQuery({
    queryKey,
    queryFn: withQueryParams(listTasksSize),
    enabled: !!selectedClinic?.referenceId && !skip,
  })

  useServerSentEvent(ServerSentResourceType.TODO_LIST_TASK, 'CREATED', () => todos.refetch())
  useServerSentEvent(ServerSentResourceType.TODO_LIST_TASK, 'UPDATED', () => todos.refetch())

  const value = useMemo(
    () => ({
      todos: todos.data?.size ?? 0,
      queryDetails: todos,
      isOpen,
      setIsOpen,
    }),
    [todos, isOpen]
  )

  useEffect(() => {
    setIsOpen(false)
  }, [selectedClinic])

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export default TodoListTasksProvider

export const useTodoListTasksProvider = () => {
  const context = React.useContext(Context)

  if (context === undefined) {
    throw new Error('useTodoListTasksProvider must be used within a TodoListTasksProvider')
  }

  return context
}
